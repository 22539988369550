import { useContext, useEffect } from 'react';
import './App.css';
import MobileAppRedirect from './MobileAppRedirect ';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {

  return (
    <div className="App">
<MobileAppRedirect/>
    </div>
  );
}

export default App;
