import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import img1 from './6d47e07d-e088-4ea9-8693-9ced9e789f28.JPG'
import img2 from './IMG_8261.PNG'
import store from './store.png'
import goo from './goo.png'
const MobileAppRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the user is on an Android device
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.BeNeshtyStore';
    }
    // Check if the user is on an iOS device
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/eg/app/beneshty/id1596605724';
    }
    // If the user is on a laptop or PC, show a message
    else {
      const AppMessage = () => {
        return (
         <section className='pt-5'>
            <Container>
                <Row>
                    <Col>
                    <div className='img__body'>
                    <img alt='' src={img1} className='img'/>
                    <img alt='' src={img2}  className='img2'/>
                    </div>
                    </Col>
                    <Col className='text'>
                   <h5>App Is Available</h5>
                   <p>Download our app is available on app store for Both platform Andorid and IOS .</p>
                   <div className='down'>
                   <img alt='' src={store} onClick={()=>window.location.href = 'https://apps.apple.com/eg/app/beneshty/id1596605724'}/>
                   <img alt='' src={goo} onClick={()=>window.location.href = 'https://play.google.com/store/apps/details?id=com.BeNeshtyStore'}/>
                   </div>
                    </Col>
                </Row>
            </Container>
         </section>
        );
      };

      ReactDOM.render(<AppMessage />, document.body);
    }
  }, []);

  return null; // This component does not render any UI elements
};

export default MobileAppRedirect;